import * as blazeface from "@tensorflow-models/blazeface";
import * as tf from "@tensorflow/tfjs";

import React, { useCallback, useEffect, useRef, useState } from "react";
import cookie from "react-cookies";
import constants from "../../Constants";
const baseURL = constants.baseURL;

const FaceCapture = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [faceDetected, setFaceDetected] = useState(false);
  const [model, setModel] = useState(null);
  // Function to capture the current video frame and upload it
  const captureFace = useCallback(
    async (face) => {
      if (!videoRef.current || !canvasRef.current) {
        console.log("No video or canvas ref");
        return;
      }

      if (!faceDetected && !face) {
        console.log("No face detected");
        return;
      }
      console.log("captureFace");
      const video = videoRef.current;
      const canvas = canvasRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const metadata = {
        title: "Face Image",
        description: "Face image captured for identity verification",
        location: "South Africa",
      };
      // Get the current video frame as an image
      const file = new File([canvas.toDataURL("image/png")], "face.png", {
        type: "image/png",
      });
      // Convert the canvas image to a Blob (PNG format) and upload it
      canvas.toBlob(async (blob) => {
        if (!blob) return;
        try {
          // 1. Fetch the presigned URL from your backend.
          const presignedResponse = await fetch(
            baseURL + "/face-file-upload-url",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: cookie.load("hq-id-qt"),
              },
              body: JSON.stringify({
                file: {
                  lastModified: file.lastModified,
                  lastModifiedDate: file.lastModifiedDate,
                  name: file.name,
                  originalname: file.name,
                  size: file.size,
                  type: file.type,
                  mimetype: file.mimetype,
                  webkitRelativePath: file.webkitRelativePath,
                },
                filetype: file.type,
                metadata,
              }),
            }
          );
          if (!presignedResponse.ok) {
            throw new Error("Failed to get presigned URL");
          }
          const presignedUrl = await presignedResponse.text();
          // 2. Upload the Blob directly to S3 using the presigned URL.
          const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "image/png",
            },
            body: blob,
          });
          if (uploadResponse.ok) {
            setUploadStatus("Upload successful!");
            //set cookie for the face detection for 2 hours
            document.cookie = "face_detection=true;max-age=7200";
            //redirect the suer to the dashboard after the face detection
            setTimeout(() => {
              window.location.href = "/dashboard";
            }, 4000);
          } else {
            setUploadStatus("Upload failed.");
            console.error("Upload response:", uploadResponse);
          }
        } catch (error) {
          console.error("Error during upload:", error);
          setUploadStatus("Upload failed due to an error.");
        }
      }, "image/png");
    },
    [faceDetected, videoRef, canvasRef]
  );

  // Load the BlazeFace model on component mount
  useEffect(() => {
    if (!cookie.load("hq-id-qt") && window.location.pathname !== "/login") {
      window.location.href = "/login";
      return;
    }
    if (
      cookie.load("hq-super-qt") === "super" &&
      window.location.pathname === "/facialrecognition"
    ) {
      window.location.href = "/dashboard";
      return;
    }

    if (cookie.load("hq-super-qt") === "super") {
      return;
    }

    const loadBlazeFaceModel = async () => {
      try {
        // Set the backend (try 'webgl' first; if unavailable, you could use 'cpu')
        await tf.setBackend("webgl");
        // Wait until the backend is ready
        await tf.ready();
        // Now load the BlazeFace model
        const loadedModel = await blazeface.load();
        setModel(loadedModel);
        console.log("BlazeFace model loaded");
      } catch (error) {
        console.error("Error loading BlazeFace model:", error);
        setErrorMessage(`Error loading BlazeFace model: ${error.message}`);
      }
    };
    if (document.cookie.indexOf("face_detection") === -1) loadBlazeFaceModel();
    //if there is no cookie for the face detection, then we will not allow the user to proceed, as long as the url is not the facial recognition page
    if (
      document.cookie.indexOf("face_detection") === -1 &&
      window.location.pathname !== "/facialrecognition"
    ) {
      window.location.href = "/facialrecognition";
    }
    let localStream = null;
    const startCamera = async () => {
      try {
        localStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = localStream;
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
        setErrorMessage(
          "Unable to access camera. Please ensure your device has a camera and that you have granted permission."
        );
      }
    };
    if (document.cookie.indexOf("face_detection") === -1) startCamera();
    // Cleanup: stop all video tracks when the component unmounts

    return () => {
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  // Real‑time face detection using BlazeFace
  useEffect(() => {
    let detectionInterval = null;
    if (model && videoRef.current) {
      detectionInterval = setInterval(async () => {
        if (videoRef.current && videoRef.current.readyState === 4) {
          try {
            const predictions = await model.estimateFaces(
              videoRef.current,
              false
            );
            if (
              predictions &&
              predictions.length === 1 &&
              predictions[0].probability > 0.999
            ) {
              console.log("Predictions:", predictions);
              setFaceDetected(predictions.length === 1);
              captureFace(true);
              return;
            }
            setFaceDetected(false);
          } catch (err) {
            console.error("Face detection error:", err);
          }
        }
      }, 500);
    }
    return () => {
      if (detectionInterval) clearInterval(detectionInterval);
    };
  }, [model, captureFace]);

  // Inline styling for a modern, card-style layout
  const containerStyle = {
    maxWidth: "600px",
    margin: "40px auto",
    background: "#fff",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    textAlign: "center",
  };

  const headerStyle = {
    fontSize: "26px",
    fontWeight: "600",
    color: "#333",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    color: "#555",
    lineHeight: "1.6",
    marginBottom: "20px",
  };

  const videoStyle = {
    width: "100%",
    maxWidth: "400px",
    borderRadius: "8px",
    background: "#000",
    margin: "20px auto",
    display: "block",
  };

  const buttonStyle = {
    padding: "12px 24px",
    fontSize: "16px",
    color: "#fff",
    background: "#007bff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background 0.3s ease",
  };

  const errorStyle = {
    color: "red",
    margin: "10px 0",
  };

  const statusStyle = {
    margin: "10px 0",
    fontSize: "16px",
    color: "#333",
  };

  const faceStatusStyle = {
    margin: "10px 0",
    fontSize: "15px",
    color: faceDetected ? "green" : "orange",
    fontWeight: "bold",
  };

  return (
    document.cookie.indexOf("face_detection") === -1 &&
    cookie.load("hq-super-qt") !== "super" && (
      <div style={containerStyle}>
        <h2 style={headerStyle}>Identity Verification Process</h2>
        <p style={paragraphStyle}>
          To comply with POPI (Protection of Personal Information) regulations
          and ensure the security of driver+customer data at QikTruck, we
          require real‑time identity verification. Please ensure your face is
          clearly visible in the camera. Our system uses advanced face detection
          to verify that a face is present before allowing you to capture your
          image for secure processing.
        </p>

        {errorMessage && <p style={errorStyle}>{errorMessage}</p>}

        <p style={faceStatusStyle}>
          {faceDetected ? (
            "Face Detected"
          ) : (
            <>
              <span style={{ color: "red" }}>No Face Detected</span> - Please
              ensure your face is clearly visible in the camera.
              <br />
              <span style={{ color: "red" }}>Note:</span> You will not be able
              to proceed without a face detection.
              <span style={{ color: "red" }}>
                {" "}
                If you are having trouble, please refresh the page.
              </span>
              <br />
              <span style={{ color: "blue" }}>
                Move your face closer to the camera
              </span>
            </>
          )}
        </p>
        {/* flip */}
        <video ref={videoRef} autoPlay playsInline style={videoStyle} />
        <canvas ref={canvasRef} style={{ display: "none" }} />
        {faceDetected && (
          <div style={{ marginTop: "1rem" }}>
            <button onClick={captureFace} style={buttonStyle}>
              Capture Face
            </button>
          </div>
        )}

        {uploadStatus && <p style={statusStyle}>{uploadStatus}</p>}
      </div>
    )
  );
};

export default FaceCapture;
