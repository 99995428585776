import { Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import cookie from "react-cookies";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row
} from "reactstrap";
import constants from "../../Constants";

// Import images (update paths as necessary)
import heroImage from "../../assets/images/hero.jpg";

const baseURL = constants.baseURL;

const Section = () => {
  // State variables for the OTP login functionality
  const [loading, setLoading] = useState(false);
  const [apierror, setApierror] = useState("");
  const [idnumber, setIdnumber] = useState("");
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);

  // Validate the first step (admin id entered)
  const validStepOne = () => idnumber.trim() !== "";
  // Validate the second step (OTP entered)
  const validStepTwo = () => idnumber.trim() !== "" && otp.trim() !== "";

  // Request OTP from the server based on the admin id (idnumber)
  const getAdminOTP = () => {
    setLoading(true);
    setApierror("");
    axios
      .post(`${baseURL}/admin-otp`, { idnumber })
      .then(() => {
        setLoading(false);
        setShowPasswordField(true);
      })
      .catch((e) => {
        console.error(e);
        alert(
          "An account error occurred. Please try again or contact your admin.\nYour account may need to be activated first."
        );
        setLoading(false);
      });
  };

  // After a successful OTP validation, get the admin profile and redirect
  const getAdminProfile = (token) => {
    axios
      .get(`${baseURL}/get-admin-profile`, {
        headers: { authorization: token },
      })
      .then((response) => {
        cookie.save("admin-profile", response.data, {
          path: "/",
          maxAge: 186400,
          secure: true,
        });
      })
      .then(() => {
        setLoading(false);
        setApierror("");
        window.location.href = "/dashboard";
      })
      .catch((e) => {
        console.error("/getAdminProfile err", e);
        setLoading(false);
        setApierror("Request failed. Please try again.");
      });
  };

  // Validate the OTP provided by the user and proceed if valid
  const validateOTP = () => {
    setLoading(true);
    setApierror("");
    axios
      .post(`${baseURL}/validate-admin-otp`, { idnumber, otp })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message && response.data.message.length > 0) {
            setMessage(response.data.message);
          } else {
            // Optionally extract additional profile information here
            const {
              adminid,
              email,
              mobilenumber,
              firstname,
              lastname,
              idnumber: adminIdnumber,
              dateadded,
              adminstatus,
              message: responseMessage,
              level,
            } = response.data;

            // Save cookies (adjust names and options as needed)
            cookie.save("hq-id-qt", response.data["hq-id-qt"], {
              path: "/",
              maxAge: 86400,
              secure: true,
            });
            cookie.save("hq-super-qt", response.data.level, {
              path: "/",
              maxAge: 86400,
              secure: true,
            });
            cookie.save("hqName", firstname, {
              path: "/",
              maxAge: 86400,
              secure: true,
            });
            cookie.save(
              "profile",
              JSON.stringify({
                adminid,
                email,
                mobilenumber,
                firstname,
                lastname,
                idnumber: adminIdnumber,
                dateadded,
                adminstatus,
                message: responseMessage,
                level,
              }),
              {
                path: "/",
                maxAge: 86400,
                secure: true,
              }
            );
            getAdminProfile(response.data["hq-id-qt"]);
          }
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setApierror("Request failed. Please try again.");
      });
  };

  return (
    <>
      {/* Hero Section */}
      <section
        className="hero-section d-flex align-items-center"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          minHeight: "50vh",
          position: "relative",
          color: "#fff",
        }}
      >
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        ></div>
        <Container style={{ position: "relative", zIndex: 2 }}>
          <Row className="justify-content-center text-center">
            <Col md={8}>
              <h1 className="display-4 font-weight-bold white-text">
                Move Anything, Anytime, Anywhere
              </h1>
              <p className="lead my-4">
                Fast, reliable, and efficient moving &amp; delivery services.
              </p>
              <Button
                color="primary"
                size="lg"
                href="https://qiktruck.co.za/quote?from=hq-website"
              >
                Get a Quote <i className="mdi mdi-arrow-right ml-2"></i>
              </Button>
            </Col>
          </Row>
        </Container>
      </section>



      {/* Admin Login Section */}
      <section className="admin-login-section py-5 bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <Card className="shadow-sm">
                <CardBody>
                  <h2 className="h4 text-center mb-4">Admin Login</h2>

                  {/* Modal for messages */}
                  <Modal isOpen={message.length > 0} size="sm" centered>
                    <ModalBody>
                      <Row className="justify-content-center">
                        <Col className="text-center">
                          <h4>{message}</h4>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>

                  {!showPasswordField && (
                    <Form>
                      <FormGroup>
                        <Label for="idnumber">ADMIN ID</Label>
                        <Input
                          type="text"
                          id="idnumber"
                          placeholder="Enter your Admin ID"
                          value={idnumber}
                          onChange={(e) => setIdnumber(e.target.value)}
                        />
                      </FormGroup>
                      <div className="text-right">
                        <Button
                          color="primary"
                          onClick={getAdminOTP}
                          disabled={!validStepOne() || loading}
                        >
                          Next {loading && <Spin />}
                        </Button>
                      </div>
                    </Form>
                  )}

                  {showPasswordField && (
                    <Form>
                      <div className="mb-3">
                        <Button
                          color="link"
                          onClick={() => setShowPasswordField(false)}
                        >
                          <i className="mdi mdi-arrow-left"></i> Back
                        </Button>
                      </div>
                      <FormGroup>
                        <Label for="otp">
                          Check your email (<strong>{idnumber}</strong>)
                        </Label>
                        <Input
                          type="password"
                          id="otp"
                          placeholder="Enter One Time PIN"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </FormGroup>
                      <div className="text-right">
                        <Button
                          color="primary"
                          onClick={validateOTP}
                          disabled={!validStepTwo() || loading}
                        >
                          Next {loading && <Spin />}
                        </Button>
                      </div>
                    </Form>
                  )}
                  {!loading && apierror && (
                    <p className="text-danger text-center mt-3">{apierror}</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

    </>
  );
};

export default Section;